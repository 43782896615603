<template>
  <div :class="item.classes" v-html="getHtml"></div>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'
import { trimWhitespace } from 'theme/filters/trim-whitespace'

export default {
  name: 'RawHTML',
  extends: Blok,
  components: {
  },
  computed: {
    getHtml () {
      return this.item.html + '<style>' + this.item.css + '</style>'
    }
  },
  filters: {
    trimWhitespace
  }
}
</script>

<style v-text="item.css">
</style>
